import React, { useState } from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import contactBg from "../assets/kontakt_bg.png"
import { MobileRule, API_ENDPOINT } from "../constants"
import axios from "axios"

const Contact = () => {
  const [status, setStatus] = useState(0)
  const [error, setError] = useState(false)

  return (
    <section
      id="kontakt"
      css={{
        background: `url('${contactBg}') center center no-repeat`,
        backgroundSize: "cover",
        display: "flex",
        padding: "100px 4rem",
        [MobileRule]: {
          padding: "70px 2rem",
          flexDirection: "column",
        },
      }}
    >
      <div
        css={{
          [MobileRule]: { flexDirection: "column" },
          maxWidth: "1264px",
          margin: "auto",
          display: "flex",
        }}
      >
        <div css={{ flex: 1, marginRight: "2rem" }}>
          <h4
            css={{
              marginTop: 0,
              marginBottom: "1.5rem",
              color: "white",
              fontSize: "46px",
              letterSpacing: "-0.015em",
              lineHeight: "50px",
              fontWeight: 600,
            }}
          >
            Kontakta oss!
          </h4>
          <p
            css={{
              marginBottom: 0,
              fontSize: "1rem",
              marginTop: 0,
              lineHeight: "1.4em",
              color: "#323943",
            }}
          >
            Bokföring, redovisning, skatter, löner och allt annat som kan
            behövas för en väl fungerande hantering av bolagets ekonomi.
          </p>
          <p
            css={{
              marginTop: 0,
              fontSize: "1rem",
              lineHeight: "1.4em",
              color: "#323943",
            }}
          >
            Vi finns här!
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <form id="contactForm" onSubmit={handleSubmit}>
            <div
              css={{
                display: "flex",
                [MobileRule]: { flexDirection: "column", minHeight: "100px" },
              }}
            >
              <div
                css={{
                  [MobileRule]: { margin: 0 },
                  margin: "0 0.5rem",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <input
                  css={{
                    height: "2.65em",
                    padding: "0 0.65em",
                    fontSize: "13px",
                    color: "#555",
                    marginBottom: "1.004em",
                  }}
                  type="text"
                  name="name"
                  placeholder="Ditt namn"
                />
                <input
                  css={{
                    marginTop: "6px",
                    height: "2.65em",
                    padding: "0 0.65em",
                    fontSize: "13px",
                    color: "#555",
                    marginBottom: "1.004em",
                  }}
                  type="email"
                  name="email"
                  placeholder="Din e-post"
                />
                <input
                  css={{
                    marginTop: "6px",
                    height: "2.65em",
                    padding: "0 0.65em",
                    fontSize: "13px",
                    color: "#555",
                    marginBottom: "1.004em",
                  }}
                  type="text"
                  name="subject"
                  placeholder="Ämne"
                />
              </div>
              <div
                css={{
                  [MobileRule]: { margin: 0 },
                  margin: "0 0.5rem",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <textarea
                  css={{
                    [MobileRule]: { minHeight: "100px" },
                    flex: 1,
                    marginBottom: "1.004em",
                    fontSize: "13px",
                    padding: "8px 0.65em 3px 0.65em",
                    color: "#555",
                  }}
                  name="message"
                  placeholder="Meddelande"
                />
              </div>
            </div>
            <div
              css={{
                marginRight: "1rem",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
              }}
            >
              <input
                css={{
                  marginTop: "0.5rem",
                  borderRadius: "25px",
                  fontWeight: "700",
                  backgroundColor: "#333a44",
                  color: "white",
                  padding: "0.629em 2.143em 0.643em",
                  fontSize: "14px",
                  cursor: "pointer",
                  opacity: status === 0 ? 1 : 0.5,
                }}
                value={
                  status === 0
                    ? "Skicka!"
                    : status === 1
                    ? "Skickar..."
                    : "Skickat!"
                }
                type="submit"
              />
            </div>
            {error && (
              <p
                css={{
                  borderLeftWidth: "5px",
                  margin: "1.5em 8px 0 !important",
                  padding: "0.824em 1.15em 1em",
                  marginBottom: "1.5em",
                  borderLeft: "5px solid #f6dca7",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  color: "#c09853",
                  backgroundColor: "#fcf8e3",
                  transition: "all .2s ease-out",
                }}
              >
                Ett eller flera fält har fel. Kontrollera och försök igen.
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  )

  function handleSubmit(event) {
    event.preventDefault()
    if (status !== 0) {
      return
    }
    const data = new FormData(event.target)
    var error = false
    if (data.get("email").length < 2) {
      error = true
    } else if (data.get("name").length < 2) {
      error = true
    } else if (data.get("subject").length < 2) {
      error = true
    } else if (data.get("message").length < 2) {
      error = true
    }
    if (error) {
      setError(true)
      return
    } else {
      setError(false)
    }

    setStatus(1)

    axios({
      method: "post",
      headers: { "Content-type": "application/json" },

      url: API_ENDPOINT + "/clearly-send-mail",
      data: {
        email: data.get("email"),
        name: data.get("name"),
        subject: data.get("subject"),
        message: data.get("message"),
      },
    })
      .then(() => setStatus(2))
      .catch(function(error) {
        console.log(error)
      })
  }
}

export default Contact
