import React from "react"
import Team from "../assets/team.jpg"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { MobileRule } from "../constants"
import AnchorLink from "react-anchor-link-smooth-scroll"

class Jobs extends React.Component {
  render() {
    return (
      <section css={{ display: "flex", [MobileRule]: {flexDirection: 'column'} }}>
        <div
          css={{
            [MobileRule]: {flex: 'auto', paddingTop: '40%'},
            flex: 1,
            background: `url('${Team}') top center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div
          css={{
            [MobileRule]: {padding: '2.5rem 1rem', textAlign: 'center', justifyContent: 'center'},
            backgroundColor: "#323943",
            paddingTop: "70px",
            paddingBottom: "70px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div css={{ [MobileRule]: {padding: "0px" }, paddingLeft: "4rem",maxWidth: "600px" }}>
            <h2
              css={{
                margin: "0 0 0.5rem 0",
                color: "white",
                fontSize: "32px",
                lineHeight: "38px",
                [MobileRule]: {fontSize: '24px', lineHeight: '30px', overflow: 'hidden'}
              }}
            >
              Är du redovisningsekonom?
            </h2>
            <p css={{ [MobileRule]: {padding: "0rem 1rem"},color: "white", fontSize: "14px", lineHeight: "22px" }}>
              Vi är alltid intresserade av att komma i kontakt med positiva och kompetenta medarbetare. Här kan du läsa mer om hur det är att jobba på Clearly och ta del
              framtida jobbmöjligheter.
            </p>
            <div css={{ marginTop: "2rem" }}>
              <AnchorLink
              href="#kontakt"
                css={{
                  color: "white",
                  padding: "0.75rem 2rem",
                  fontWeight: '700',
                  cursor: "pointer",
                  textDecoration: "none",
                  backgroundColor: "#9DCBEE",
                  borderRadius: "25px",
                  display: 'inline-block',
                }}
              >
                Sök här
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Jobs
